/*******************************************************************************
*****  Utility functions for css styles  ***************************************
*******************************************************************************/

/**
 * Function to capitalize a string.
 * First character to upper case.
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

/**
 * Function to get style class of css module by property.
 */
export const cls = (name, value, styles) => styles[name + capitalize(value)]