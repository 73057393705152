/* Library Imports */
import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"


/* Local Imports */
import {cls} from "../../utils/styles"
import styles from "./index.module.scss"
import Avatar from "../avatar";


/* Component */
const Balloon = (props) => {

  return (
    <div className={clsx("balloon", styles.balloon,
      cls("justify", props.justify, styles),
      props.author === undefined && styles.noAuthor
    )}>
      <Title title={props.title}/>
      <Lettering>
        <Author author={props.author}/>
        <Content>{props.children}</Content>
      </Lettering>
    </div>
  )
}


/* Children */
const Title = (props) => props.title === undefined ? <></> : (
  <div className={clsx("title", styles.title)}>
    <h2>{props.title}</h2>
  </div>
)

const Lettering = (props) => (
  <div className={clsx("lettering", styles.lettering)}>
    {props.children}
  </div>
)

const Author = (props) => props.author === undefined ? <></> : (
  <div className={clsx("author", styles.author)}>
    <Avatar person={props.author}/>
  </div>
)

const Content = (props) => (
  <div className={clsx("content", styles.content)}>
    {props.children}
  </div>
)


/* Component Properties */
Balloon.propTypes = {
  justify: PropTypes.oneOf([
    "left", "right"
  ]),
  title: PropTypes.string,
  author: PropTypes.object
}

Balloon.defaultProps = {
  justify: "left",
  title: undefined,
  author: undefined,
}


/* Multiple Components Wrapper */
Balloon.Connector = (props) => (
  <div className={clsx("connector", styles.balloonConnector,
    cls("justify", props.justify, styles)
  )}>
    {props.children}
  </div>
)

/* Wrapper Properties */
Balloon.Connector.propTypes = {
  justify: PropTypes.oneOf([
    "left", "right"
  ])
}

Balloon.Connector.defaultProps = {
  justify: "left"
}


/* Exports */
export default Balloon