import React from "react"

import PropTypes from "prop-types"

const EmailLink = (props) => {
  return (
    <a className={"email-link"} href={`mailto:${props.to}`} tabIndex={0}>{ props.children }</a>
  )
}

EmailLink.propTypes = {
  to: PropTypes.string,
}

export default EmailLink