/* Library Imports */
import React from "react"
import clsx from "clsx"


/* Local Imports */
import Image from "../image"
import EmailLink from "../email"
import ButtonLink, {MailButtonLink} from "../button/link"
import styles from "./index.module.scss"


/* Component */
const Contact = (props) => {

  return (
    <div className={clsx("contact", styles.contact,
    )}>
      <div className={clsx("content", styles.content)}>
        <div className={clsx("picture", styles.picture)}>
          <Image
            shape={"circle"}
            src={props.imageSrc}
            fluid={props.imageFluid}
            fixed={props.imageFixed}
          />
        </div>

        <div className={clsx("data", styles.data)}>

          { props.title && <h2>{ props.title }</h2> }
          { props.subtitle && <p>{ props.subtitle }</p> }

          { (props.street || props.city) && (
            <p>
              { props.street && <span>{ props.street }<br/></span> }
              { props.city && <span>{ props.city }</span> }
            </p>
          )}

          { (props.phone || props.mail) && (
            <p>
              { props.phone && <span>{ props.phone }<br/></span> }
              { props.mail && <EmailLink to={ props.mail }>{ props.mail }</EmailLink> }
            </p>
          )}

          <Action
            type={ props.buttonType }
            url={ props.buttonUrl }
            text={ props.buttonText }
          />
        </div>
      </div>
    </div>
  )
}


/* Children */
const Action = ({ type, url, text }) => {

  switch(type) {
    case "link":
      return <ButtonLink href={ url }>{ text }</ButtonLink>

    case "mail":
      return <MailButtonLink mailto={ url }><span>{ text }</span></MailButtonLink>

    default:
      return <></>
  }
}


/* Exports */
export default Contact