import React, {useEffect} from "react"

import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Box from "../../components/box"
import HeaderSection from "../../components/header"
import Animation from "../../components/animation"
import Carousel from "../../components/carousel"
import QuoteBox, { Quote } from "../../components/quote"
import Image from "../../components/image"
import { Link } from "gatsby"
import Balloon from "../../components/balloon";
import Contact from "../../components/contact";

import "../../styles/index.scss"
import "./index.css"

import ArrowRightCurve from  "../../../static/assets/icons/arrow-down-right-curve.svg";
import ArrowLeftCurve from  "../../../static/assets/icons/arrow-down-left-curve.svg";
import DoubleArrowDown from  "../../../static/assets/icons/double-arrow-down.svg";
import InfoIcon from "../../../static/assets/icons/info.svg"
import RightArrowBlack from "../../../static/assets/icons/right-arrow-black.svg"
import BackgroundImage from "gatsby-background-image"
import BenefizArrowDown from "../../images/benefiz/DownArrow.svg"
import PartnerKomb from "../../../static/assets/icons/partner-kombi.svg"
import MediaQuery from "react-responsive/src/Component"




const ServicesPage = ({ _path, data }) => {
  var projectsectionActive=false;
  return (
    <Layout path={"/leistungen"}>
      <SEO title="Leistungen" />
      <HeaderSection
        title={"Unsere Leistungen"}
        image={data.headerImage.childImageSharp}
        type={"image-fullscreen"}
      />
      <TranscendBoundariesSection/>
        <ThreeBoxesSection data={data}/>
      <ServicesSection data = {data}
        services={data.servicesAllMdx.edges}
      />
        <ZusammenarbeitMitUnsSection data={data}/>
      <IntroMachineSection />
      <MachineSection />
      <TogetherWorkingSection
        people={[data.balloonPerson1, data.balloonPerson2]}
      />
      {projectsectionActive ? <ProjectSection data={data} />: ""}
      <QuotesSection />
      <CooperationInterestsSection />
      <ContactSection person={data.contactPerson} />
    </Layout>
  )
}

const TranscendBoundariesSection = () => (
  <Section id={"TranscendBoundariesSection"}>
    <Container justify={"center"} direction={"column"} appear={"fromBottom"}>
      <Box alignText={"center"} padding={"horizontal"}>
        <p className={"text-size-bigger"}>
          <span>Wenn Sie mit uns zusammenarbeiten,<br /></span>
          <span className={"text-color-red text-weight-bold"}>
            verschmelzen Grenzen.
          </span>
        </p>
        <p className={"text-size-bigger text-color-red text-weight-bold"}>
          &ndash;<br />
        </p>
        <p className={"text-size-big"}>
          <span>Wir denken über konventionelle Maßstäbe hinaus, greifen Ihr Problem auf <br /></span>
          <span>und betrachten die Arbeit mit Ihnen als ganzheitlichen Ansatz. <br /></span>
          <span>Wir bleiben auch dann nicht untätig, wenn keine passenden Anforderungen formuliert sind.</span>
        </p>
      </Box>
    </Container>
  </Section>
)

const ThreeBoxesSection = ({data}) => {

   return(
    <Section>
        <Container appear={"fromLeft"}>
            <Box padding={"horizontal"} style={{marginBottom:"40px"}}  >
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                <div className={"bgImageWrapper"}>
                        <BackgroundImage fluid={data.indexImage.childImageSharp.fluid}>
                            <div className={"three-boxes-container"}>
                                <div className={"white-box"}>
                                    <span className={"boxes-span text-color-red boxes-span-title"}>Unser Kerngeschäft</span><br/>
                                    <span>Die Entwicklung maßge-<br/>schneiderter und individueller
                                Business Softwarelösungen</span>
                                </div>
                                    <img src={RightArrowBlack} alt={"double-arrow-right"} className={"white-box-arrow"}/>
                                <div className={"white-box"}>
                                    <span className={"boxes-span text-color-red boxes-span-title"}>Unsere Expertise </span><br/>
                                    <span> Ganzheitliche und <br/> komplette Businesslösungen aus einer Hand</span>
                                </div>
                                <img src={RightArrowBlack} alt={"double-arrow-right"} className={"white-box-arrow"}/>
                                <div  className={"white-box"}>
                                <span className={"boxes-span text-color-gray"}>Wir entwickeln<br/>
                                    <span className={"text-color-black"}> mit Ihnen</span>
                                    <span className={"text-color-red"}> individuelle Softwarelösungen</span>
                                    <span className={"text-color-black"}> als <br/>ganzheitlichen Ansatz.</span>
                                </span>
                                </div>
                            </div>
                        </BackgroundImage>
                </div>
                <img src={BenefizArrowDown} style={{height:"40px", width:"40px", marginTop:"80px"}} alt={"ArrowDown"}/>
                </div>
            </Box>
        </Container>
    </Section>);
}

const ServicesSection = ({data}) => {
  const [paused, setPaused] = React.useState(true);
  const [mvp, setMvp] = React.useState(false)


    return <Section id={"ServicesSection"}>
    <Container
      appear={"fromLeft"}
      onAppear={() => setPaused(false)}>

      <Box padding={"horizontal"}>
          <div className="board-container">
              <div className={"board"}>
                  <div className={"board-icon"}>
                      <h2 className={"text-color-red"}>Business Analysen<br/>
                          und Prozessreviews</h2>
                      <div className={"board-animation"}>
                          <Animation
                              src={"services/coaching.json"}
                              paused={false}
                          />
                      </div>
                  </div>
                  <div className={"board-text board-text-right"}>
                      <p style={{marginBottom:"2rem"}}>Wir starten mit einem gründlichen Prozessreview und entwickeln eine
                          Roadmap für das MVP. Dabei analysieren wir Geschäftsprozesse, um das
                          Umfeld, die Ausgangslage und die Bedürfnisse unserer Kunden genau
                          zu verstehen. Für eine qualitativ hochwertige Analyse sind folgende
                          Voraussetzungen essenziell:</p>
                      <p className={"board-text-bold"}> 1. Analysten als Partner auf Augenhöhe </p>
                      <p className={"board-text-bold"}> 2. Proaktive Weiterentwicklung der Geschäftsprozesse </p>
                      <p className={"board-text-bold"}> 3. Nahtlose Integration der Fachlichkeit in den Softwareentwicklungsprozess
                      </p>
                  </div>
              </div>
              <div className={"board-arrow  board-arrow-right"}>
                  <img src={ArrowRightCurve} alt="ArrowRightCurve"/>
              </div>
              <div className={"board board-reverse"}>
                  <div className={"board-text board-text-left"} id={"mvp-board"}>
                      <p style={{marginBottom:"2rem"}}>Durch frühzeitige MVPs und Prototypen finden wir schnell und mit
                          minimalem Aufwand heraus, ob der erste Softwareentwurf einen echten
                          Mehrwert für den Kunden schafft. Dies reduziert Overhead und Planung
                          erheblich. Gerade in komplexen Systemen verschafft uns das einen Vorteil,
                          da wir effizientere und zielgerichtetere Lösungen entwickeln können.</p>

                      <p className={"board-text-bold"} >
                          Unsere agile Arbeitsweise und die etablierte Feedbackkultur
                          führen uns schnell zum richtigen Produkt und garantieren qualitativ
                          hochwertige Software.
                          <span style={{fontWeight:"bolder", color:"#c70000", marginLeft:"5px", cursor:"pointer"}}
                                onClick={() => setMvp(!mvp)}>MVP &#x21E3;</span>
                      </p>

                      <div style={mvp ? {display:"flex", height:"100px"} : {display:"none"}}>
                          <img src={InfoIcon} alt="InfoIcon"/>
                          <p id={"mini-mvp"}>Ein Minimal Viable Product
                              (MVP) ist die einfachste Version
                              eines Produkts, die entwickelt
                              wird, um mit dem geringstmöglichen Aufwand die Kernanforderungen zu erfüllen und
                              frühzeitig erstes Feedback zu
                              sammeln.</p>
                      </div>
                  </div>
                  <div className={"board-icon"}>
                      <h2 className={"text-color-red"} style={{textAlign:"center"}}>MVPs und <br/> Prototyping</h2>
                      <div className={"board-animation"} style={{width:"140px"}}>
                          <Animation
                              src={"services/mvp-prototyping.json"}
                              paused={false}
                          />
                      </div>
                  </div>
              </div>
              <div className={"board-arrow  board-arrow-left"}>
                  <img src={ArrowLeftCurve} alt="ArrowLeftCurve"/>
              </div>
              <div className={"board"}>
                  <div className={"board-icon"} id={"agile-sw"}>
                      <h2 className={"text-color-red"}>Agile Softwareentwicklung</h2>
                      <span>Von Mainframe <br/> über Java und React bis Cloud</span>
                      <div className={"board-animation"}>
                          <Animation
                              src={"services/development.json"}
                              paused={false}
                          />
                      </div>
                  </div>
                  <div className={"board-text board-text-right"}>
                      <p style={{marginBottom:"2rem"}}>Wir verstehen Ihre Umgebung schnell und entwickeln maßgeschneiderte
                          Software, die perfekt passt. Wir liefern pünktlich, gut dokumentiert und in
                          hoher Qualität. Mit dem agilem Mindset passen wir unsere Lösungen individuell
                          an Ihre Bedürfnisse an.</p>
                      <p className={"board-text-bold"}  style={{marginBottom:"2rem", color:"#575353"}}> Unsere Software ist …</p>
                      <p className={"board-text-bold"}>… immer auf dem neuesten Stand der Technik
                      </p>
                      <p className={"board-text-bold"}> … nachhaltig
                      </p>
                      <p className={"board-text-bold"}> … spezifisch an Ihre Anforderungen angepasst
                      </p>
                  </div>
              </div>
              <div className={"board-arrow  board-arrow-right"}>
                  <img src={ArrowRightCurve} alt="ArrowRightCurve"/>
              </div>
              <div className={"board board-reverse"}>
                  <div className={"board-text board-text-left"}>
                      <p style={{marginBottom:"2rem"}}>Wir liefern zuverlässigen Betrieb und Support der von uns entwickelten
                          individuellen Softwarelösungen – ganz ohne starre Prozesse, sondern mit
                          100%igem Fokus auf das Lösen von Problemen.</p>
                      <p className={"board-text-bold"}>
                          Für langfristigen Betrieb und Support arbeiten wir mit unserem
                          Partnerunternehmen <Link to={"https://blue-atlas.de/"}
                                                   style={{textDecoration:"underline", textDecorationColor:"#c70000"}}>Blue Atlas Technologies GmbH</Link> zusammen
                      </p>
                  </div>
                  <div className={"board-icon"}>
                      <h2 className={"text-color-red"}>Betrieb und Support</h2>
                      <div className={"board-animation"}>
                          <Animation
                              src={"services/prototyping.json"}
                              paused={false}
                          />
                      </div>
                  </div>
              </div>
          </div>
      </Box>
    </Container>
  </Section>
}

const ZusammenarbeitMitUnsSection = ({data}) => {
    const [automotive, setAutomotive] = React.useState(false)
    const [energy, setEnergy] = React.useState(false)
    const [branch, setBranch] = React.useState(false)
    const [autoSlide, setAutoSlide] = React.useState(false)

    return(
    <Section id={"IntroMachineSection"}>
        <Container appear={"fromBottom"}>
            <Box padding={"horizontal"} alignText={"center"} sizeText={"bigger"}>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>

                <div className={"bgImageWrapper"}>
                    <BackgroundImage fluid={data.indexImage.childImageSharp.fluid}>
                        <div style={{height:"100px", display:"flex", alignItems:"center", justifyContent:"center"}}>Was die Zusammenarbeit mit uns besonders macht
                        </div>
                    </BackgroundImage>
                </div>
                    <img src={BenefizArrowDown} style={{height:"40px", width:"40px", marginTop:"80px"}} alt={"ArrowDown"}/>
                </div>
            </Box>
                        <div className={"zusammenarbeit-boxes-container"}>
                            <div className={"zusammenarbeit-box"}>
                                <h2 className={"text-color-red text-weight-bolder"} style={{marginBottom:"20px"}}>Business first
                                </h2>
                                <p>Komplexe Software braucht ein tiefes Verständnis für die inhaltlichen Anforderungen und Herausforderungen. Deshalb liegt unser Fokus darauf, die fachliche Domäne unserer Kunden vollständig zu verstehen, um auf Augenhöhe mitwirken und eigene Ideen für optimale Lösungen einbringen zu können.
                                </p>
                                <p className={"text-weight-bolder text-color-black"} style={{marginTop:"20px"}}>Automotive</p>
                                <p>Unser Know-how im Automotive-Bereich spezialisiert sich auf die Integration und Analyse von Fahrzeugstrukturdaten, der Optimierung von Homologationsprozessen und der präzisen Emissionsbewertung.
                                </p>
                                <p style={automotive ? {opacity:1, visibility:"visible", maxHeight:"120px", width:"100%", transition:"all 0.3s ease"} :
                                    {opacity:0, visibility:"hidden", maxHeight:"10px", transition:"all 0.3s ease"}}>
                                    Integration und Analyse
                                    von Fahrzeugstrukturdaten,
                                    der Optimierung
                                    von Homologationsprozessen und der präzisen
                                    Emissionsbewertung.
                                </p>


                                <p className={"text-weight-bolder text-color-black"} style={{marginTop:"20px"}}>Energy/Telekommunikation</p>
                                <p>Wir entwickeln individuelle Lösungen für die Abbildung von Marktprozessen in den Bereichen Strom, Gas und Telekommunikation,
                                    sowohl für Privat- als auch für Geschäftskunden.
                                </p>
                                <p style={energy ? {opacity:1, visibility:"visible", maxHeight:"320px", width:"100%", transition:"all 0.3s ease"} :
                                    {opacity:0, visibility:"hidden", maxHeight:"10px", transition:"all 0.3s ease"}}>
                                    Dazu zählen die Erstellung und Integration von Mobilfunkportfolios sowie die Entwicklung von Customer-Self-Service Portalen.
                                    Unser Team übernimmt dabei die komplette Entwicklung, den Betrieb mittels DevOps-Praktiken und den fortlaufenden Support dieser Systeme.
                                </p>
                                <p className={"text-color-gray"} style={{textAlign:"right", textDecoration:"underline", cursor:"pointer", display:"block"}}
                                   onClick={() => setEnergy(!energy)}>{energy? "weniger anzeigen" : "weiterlesen" }
                                </p>

                                <p className={"text-weight-bolder text-color-black"} style={{marginTop:"20px"}}>Branchenübergreifend</p>
                                <p>
                                    Als Full-Service-Provider verfügen wir über branchenübergreifendes Fachwissen und die Stärke, mit einem ganzheitlichen Ansatz auf die spezifischen Herausforderungen unserer
                                </p>
                                <p style={branch ? {opacity:1, visibility:"visible", maxHeight:"320px", width:"100%", transition:"all 0.3s ease"} :
                                    {opacity:0, visibility:"hidden", maxHeight:"10px", transition:"all 0.3s ease"}}>
                                    Kunden einzugehen. Unser Ziel ist es stets, eine maßgeschneiderte technische Lösung zu entwickeln, die genau auf die individuellen Probleme zugeschnitten ist.
                                    Wir decken den gesamten Prozess ab – von der detaillierten Analyse über die Entwicklung und Bereitstellung bis hin zum fortlaufenden Support der entstehenden Softwarelandschaft.
                                </p>
                                <p className={"text-color-gray"} style={{textAlign:"right",textDecoration:"underline", cursor:"pointer", display:"block"}}
                                   onClick={() => setBranch(!branch)}>{branch? "weniger anzeigen" : "weiterlesen" }
                                </p>
                            </div>
                            <div className={"zusammenarbeit-box"}>
                                <h2 className={"text-color-red text-weight-bold"} style={{marginBottom:"20px"}}>Wir sind ein
                                    gesellschaftergeführtes
                                    Unternehmen.
                                </h2>
                                <p>Dies bedeutet für uns eine besondere Nähe und Verantwortung zu unseren Projekten und Kunden. Unsere Gesellschafter und Mitarbeiter arbeiten Hand in Hand, um gemeinsam die besten Ergebnisse zu erzielen.</p>
                                <p style={{marginTop:"20px"}}>
                                    Statt eines überforderten Geschäftsführers, teilen wir uns die unternehmerische Verantwortung über acht Partner. So ermöglichen wir es, dass neben einem gut funktionierenden Unternehmen vor allem eins sichergestellt ist: Exzellenz in unseren Projekten. Jeder Partner bringt seine Leidenschaft und sein Engagement auch weiterhin operativ ein und teilt dies mit unseren Angestellten, Projektmitgliedern und Kunden.</p>
                                <p className={"text-weight-bold"} style={{marginTop:"20px"}}>Unser Team lebt durch offene Kommunikation, gegenseitiges Vertrauen und ein starkes Wir-Gefühl. Dies spiegelt sich nicht nur in unseren Projektergebnissen wider, sondern auch in der Art und Weise, wie wir miteinander und mit unseren Kunden interagieren. Dass diese enge Zusammenarbeit zu erfolgreichen Ergebnissen führt, bestätigen uns unsere Kunden immer wieder.</p>
                                <p style={{marginTop:"20px"}}>Erleben Sie die Vorteile einer partnerschaftlichen Zusammenarbeit mit Red Oak Consulting & Services for IT GmbH & Co. KG.</p>
                            </div>
                            <div className={"zusammenarbeit-box"}>
                                <h2 className={"text-color-red text-weight-bold"}>Wir haben starke Partner
                                    an unserer Seite
                                </h2>
                                <img src={PartnerKomb} alt={"Partner"} id={"partner-kombi"}/>
                                <p>Wir sind ein gut vernetztes Unternehmen mit zuverlässigen Partnern an unserer Seite. Über die Jahre haben wir uns ein vertrauensvolles Netzwerk aufgebaut, um unsere Qualitätsansprüche jederzeit anbieten zu können und auch größere Vorhaben umzusetzen.</p>
                                <p className={"text-weight-bold"} style={{marginTop:"20px"}}>
                                    <ul>
                                        <li>· Blue Atlas Technologies</li>
                                        <li>· i3 Systems</li>
                                        <li>· Softwareallianz Deutschland</li>
                                        <li>· Deerstone GmbH</li>
                                    </ul>
                                </p>
                                <p style={{marginTop:"20px"}}>Indem wir das Know-how und die Expertise unserer Partner bündeln und nutzen, können wir eine größere Bandbreite an speziell zugeschnittenen Lösungen bieten.</p>
                            </div>
                        </div>
        </Container>
    </Section>
    )
}

const IntroMachineSection = () => (
  <Section id={"IntroMachineSection"}>
    <Container justify={"center"} appear={"fromBottom"}>
      <Box padding={"horizontal"} alignText={"center"} sizeText={"bigger"}>
        <span>
          Wir sehen Ihre Probleme und beginnen,<br />
          eigenständig Lösungen zu entwickeln.<br />
          Eine selbstlaufende<wbr />
        </span>
        <span className={"text-color-red text-weight-bold"}> Problemlösemaschine.</span>
      </Box>
    </Container>
  </Section>
)

const MachineSection = () => {

  const [paused, setPaused] = React.useState(true)

  return (
    <Section>
      <Container
        appear={"fromBottom"}
        onAppear={() => setPaused(false)}
      >
        <Box background={"gray-light"}>
          <MediaQuery minWidth={768}>
            <Animation
              src={"machine.json"}
              paused={paused}
            />
          </MediaQuery>
          <MediaQuery maxWidth={767.999}>
            <Animation
              src={"machine-mobile.json"}
              paused={paused}
            />
          </MediaQuery>
        </Box>
      </Container>
    </Section>
  )
}

const TogetherWorkingSection = (props) => (
  <Section id={"DoingSection"}>
    <Container appear={"fromRight"} justify={"left"}>
      <h2 className={"title"}>Wie wir zusammenarbeiten</h2>
      <Balloon justify={"left"} author={props.people[0]}>
        <p>
          Wir arbeiten in einem Team zusammen, das Verantwortung übernimmt und stellen den Kunden nicht einfach vor
          vollendete Tatsachen.
        </p>
      </Balloon>
    </Container>
    <Container appear={"fromLeft"} justify={"right"}>
      <Balloon justify={"right"} author={props.people[1]}>
        <p>
          Wir stellen gute Arbeit in den Vordergrund und haben weniger unnötige organisatorische Aspekte. Was ist das
          konkrete Problem, wo müssen wir ansetzen und wie gelangen wir mit welchen Stakeholdern zu einer Lösung.
        </p>
      </Balloon>
    </Container>
  </Section>
)

const ProjectSection = ({ data }) => (
  <Section appear={"fromLeft"} style={{marginBottom: "5em"}}>
      <Box>
        <h2 className={"title"} style={{height: "2em"}}>Was wir gemacht haben</h2>
      </Box>
    <Container
      padding={"vertical"}
      justifyContent={"evenly"}
      appear={"instantFromLeft"}
      background={"gray-light"}
    >
      <Box
        padding={"full"}
        background={"white"}
        size={"2-to-5"}
      > 
        <Link to="/leistungen/onta">
          <Image fluid={data.firstProjectImage.childImageSharp.fluid} alt={"ONTA Illustration"} />
          <p> </p>
          <h2>ONTA (Telekommunikation)</h2>
          <p>
            Viel Vertrauen und grüne Wiese. Wie aus Zeitdruck und fehlenden Anforderungen das Vorzeigeprojekt wurde.
          </p>
        </Link>
      </Box>
      <Box
        padding={"full"}
        background={"white"}
        size={"2-to-5"}
      >
        <Image fluid={data.secondProjectImage.childImageSharp.fluid} alt={"WLTP Illustration"} />
        <p> </p>
        <h2>WLTP (Automobil)</h2>
        <p>
          Es wurde eine Softwarelösung gesucht,
          welche ...
        </p>

      </Box>
    </Container>
  </Section>
)


const QuotesSection = () => {

  const [autoSlide, setAutoSlide] = React.useState(false)

  return (
    <Section>
      <Container
        appear={"fromRight"}
        onAppear={() => setAutoSlide(false)}
      >
        <h2 className={"title"}>Was unsere Kunden sagen</h2>
        <Box display={"no-flex"}>
          <QuoteBox carousel={true}>
            <Carousel autoplay={autoSlide} className={"s"}>
              <Quote
                text={`Danke für Ihr Engagement, Ihre Bereitschaft und Begeisterung neue
            Wege zu gehen. Wir haben Antworten gefunden, wie wir die IT der
            Zukunft aktiv gestalten können – das ist der richtige Weg in die
            Zukunft.`}
                author={`Rainer Eichinger, Product Owner Berechnungsservice Audi (Steuerung/Prozesse CO2 Werte)`}
              />
              <Quote
                text={`Die Zusammenarbeit mit dem Projektteam und Red Oak war wirklich vorbildlich. Aus Nutzersicht würde ich
            sogar sagen, dass Ihr Einsatz bei der Unterstützung als Maßstab für alle anderen IT System Supports dienen
            kann. So stelle ich mir professionelles, proaktives, hilfreiches und zuverlässiges Arbeiten vor.`}
                author={`Jan Benes, Škoda Auto a.s.`}
              />
              <Quote
                text={`Sehr angenehme und zielorientierte Arbeit am Projekt. Gute und äußerst verständliche Kommunikation
            – auch für Laien – bei technischen und vertraglichen Fragen. Auch als kleines Unternehmen kann man mit
            Red Oak tolle und professionelle Lösungen umsetzen.`}
                author={`Oliver Pipa, Geschäftsführer, pipa - Beratung.Training`}
              />
            </Carousel>
          </QuoteBox>
        </Box>
      </Container>
    </Section>
  )
}

const CooperationInterestsSection = () => (
  <Section id={"CooperationInterestsSection"}>
    <Container justify={"center"} appear={"fromBottom"}>
      <Box alignText={"center"} padding={"horizontal"}>
        <p className={"text-size-bigger"}>
          <span>Sie sind an einer <wbr /></span>
          <span className={"text-color-red text-weight-bold"}>
            Zusammenarbeit interessiert
          </span>
          <span> und würden sich gerne beraten lassen?</span>
        </p>
        <p className={"text-size-bigger text-color-red text-weight-bold"}>
          &ndash;
        </p>
        <p className={"text-size-big"}>
          <span>Ihr Kontakt für alle Fragen rund um Projekte, Ideen und weitere Informationen:</span>
        </p>
      </Box>
    </Container>
  </Section>
)

const ContactSection = ({ person }) => (
  <Section id={"ContactSection"} appear={"fromRight"}>
    <Container>
      <Box size={"2-to-3"}>
        <Contact
          imageFluid={person.frontmatter.square.childImageSharp.fluid}
          title={person.frontmatter.firstName + " " + person.frontmatter.lastName}
          subtitle={person.frontmatter.jobTitle}
          street={"Westbahnhof 11"}
          city={"38118 Braunschweig"}
          phone={person.frontmatter.phone}
          mail={person.frontmatter.mail}
          buttonType={"mail"}
          buttonUrl={person.frontmatter.mail}
          buttonText={"Kontakt aufnehmen"}
        />
      </Box>
    </Container>
  </Section>
)

export default ServicesPage

export const query = graphql`
  query ServicesQuery {
    site {
      siteMetadata {
        title
      }
    }
    
    headerImage: file(relativePath: {eq: "services/header.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    indexImage: file(relativePath: { eq: "services/holz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   
    servicesAllMdx: allMdx(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { order: ASC, fields: [frontmatter___position] }
    ) {
      edges {
        node {
          id
          body
        }
      }
    }
    
    peopleAllMdx: allMdx(
      filter: { fileAbsolutePath: { regex: "/people/" } }
      sort: { order: ASC, fields: [frontmatter___position] }
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            firstName
            lastName
            jobTitle
            avatar
            animation
            portrait {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            landscape {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    
    balloonPerson1: mdx(fileAbsolutePath: {regex: "/people/dean-eckert/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    balloonPerson2: mdx(fileAbsolutePath: {regex: "/people/stephan-krauss/"}) {
      frontmatter {
        avatar
        firstName
        lastName
      }
    }
    
    firstProjectImage: file(relativePath: {eq: "services/onta/thumbnail-onta.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
          }
      }
  }

  secondProjectImage: file(relativePath: {eq: "services/wltp/thumbnail-wltp.jpg"}) {
    childImageSharp {
        fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
        }
    }
}

    contactPerson : mdx(fileAbsolutePath: {regex: "/people/matthias-geske/"}) {
      frontmatter {
        firstName
        lastName
        jobTitle
        mail
        phone
        square {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
