import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Icon from "../icon"
import Box from "../box";

const QuoteBox = (props) => {

  return (
    <div className={clsx("quote-box",
      props.carousel && "carousel-wrapper"
    )}>
      <Box background={"gray-light"} alignText={"center"}>
        { props.children }
      </Box>
      <Icon type={"quotes"} className={"top-right"} />
      <Icon type={"quotes"} rotate={180} className={"bottom-left"} />
    </div>
  )
}

QuoteBox.propTypes = {
  className: (
    PropTypes.string
  ),
  carousel: (
    PropTypes.bool
  ),
}

QuoteBox.defaultProps = {
  carousel: false,
}

export default QuoteBox

export const Quote = (props) => (
  <Box className={"quote"}>
    <p className={"text"}>{ props.text }</p>
    <p className={"author"}>{ props.author }</p>
  </Box>
)

Quote.propTypes = {
  text: (
    PropTypes.string
  ),
  author: (
    PropTypes.string
  ),
}
